import './App.css';
import Header from './component/Header';

function Home() {
  return (
    <div>
      <Header current="home"/>
      <div className="home">
        <img src="https://media.tenor.com/iCgOuohU11kAAAAC/dancing-polish-cow-at4am.gif" alt='a polish cow'/>
        <h2>Somewhat Senior</h2>
        <h3>Often Incorrect</h3>
        <h4>Software Engineer</h4>
        <br></br>
      </div>
    </div>
  );
}

export default Home;
