import './App.css';
import Header from './component/Header';

function Blogs() {
  return (
    <div>
      <Header current="blogs"/>
      <div className='blogs'>
          <h2>blogs</h2>
            <div>tbd</div>
          <h2>ideas</h2>
          <div className='ideas'>
            <ul>
                <li>three weeks in india: retro</li>
                <li>who isn't a nepo baby</li>
                <li>the tumultuous three</li>
                <li>Canada ➡ US</li>
                <li>california zephyr</li>
            </ul>
          </div>
      </div>
    </div>
  );
}

export default Blogs;
